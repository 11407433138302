// 3rd-party Plugins
@import "~highlight.js/styles/googlecode.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";

// Theme Styles
@import "./_metronic/_assets/sass/style.react.scss";

// Default Layout Skins
@import "./_metronic/_assets/sass/global/layout/header/skins/base/light.scss";
@import "./_metronic/_assets/sass/global/layout/header/skins/menu/light.scss";
@import "./_metronic/_assets/sass/global/layout/brand/skins/dark.scss";
@import "./_metronic/_assets/sass/global/layout/aside/skins/dark.scss";

// Header Skins
.kt-header-base-light {
	@import "./_metronic/_assets/sass/global/layout/header/skins/base/light.scss";
}

.kt-header-base-dark {
	@import "./_metronic/_assets/sass/global/layout/header/skins/base/dark.scss";
}

// Header Menu Skins
.kt-header-menu-light {
	@import "./_metronic/_assets/sass/global/layout/header/skins/menu/light.scss";
}

.kt-header-menu-dark {
	@import "./_metronic/_assets/sass/global/layout/header/skins/menu/dark.scss";
}

// Brand Skins
.kt-brand-dark {
	@import "./_metronic/_assets/sass/global/layout/brand/skins/dark.scss";
}

.kt-brand-light {
	@import "./_metronic/_assets/sass/global/layout/brand/skins/light.scss";
}

// Aside skins
.kt-aside-dark {
	@import "./_metronic/_assets/sass/global/layout/aside/skins/dark.scss";
}

.kt-aside-light {
	@import "./_metronic/_assets/sass/global/layout/aside/skins/light.scss";
}

// Web Icon fonts
// @import "~socicon/css/socicon.css";
// @import "~@fortawesome/fontawesome-free/css/all.min.css";
// @import "./_metronic/_assets/plugins/line-awesome/css/line-awesome.css";
// @import "./_metronic/_assets/plugins/flaticon/flaticon.css";
// @import "./_metronic/_assets/plugins/flaticon2/flaticon.css";

// Primefaces themes
@import "../node_modules/primereact/resources/primereact.min.css";
@import "../node_modules/primereact/resources/themes/nova-light/theme.css";
@import "../node_modules/primeicons/primeicons.css";

@-webkit-keyframes fadeInUpContent {
	from {
		opacity: 0;
		-webkit-transform: translate3d(0, -5%, 0);
		transform: translate3d(0, -5%, 0);
	}

	to {
		opacity: 1;
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
}

@keyframes fadeInUpContent {
	from {
		opacity: 0;
		-webkit-transform: translate3d(0, -5%, 0);
		transform: translate3d(0, -5%, 0);
	}

	to {
		opacity: 1;
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
}

.fadeInUpContent {
	-webkit-animation-name: fadeInUpContent;
	animation-name: fadeInUpContent;
}

.kt-aside-dark .kt-aside-menu .kt-menu__nav>.kt-menu__item.kt-menu__item--active>.kt-menu__heading .kt-menu__link-icon,
.kt-aside-dark .kt-aside-menu .kt-menu__nav>.kt-menu__item.kt-menu__item--active>.kt-menu__link .kt-menu__link-icon {
	color: #607d8b;
}

.kt-aside-dark .kt-aside-menu .kt-menu__nav>.kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover>.kt-menu__heading .kt-menu__link-icon,
.kt-aside-dark .kt-aside-menu .kt-menu__nav>.kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover>.kt-menu__link .kt-menu__link-icon {
	color: #607d8b;
}

.kt-aside-dark .kt-aside-menu .kt-menu__nav>.kt-menu__item.kt-menu__item--open>.kt-menu__heading .kt-menu__link-icon,
.kt-aside-dark .kt-aside-menu .kt-menu__nav>.kt-menu__item.kt-menu__item--open>.kt-menu__link .kt-menu__link-icon {
	color: #607d8b;
}

.kt-aside-dark .kt-aside-menu .kt-menu__nav>.kt-menu__item .kt-menu__submenu .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover>.kt-menu__heading .kt-menu__link-bullet.kt-menu__link-bullet--dot>span,
.kt-aside-dark .kt-aside-menu .kt-menu__nav>.kt-menu__item .kt-menu__submenu .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover>.kt-menu__link .kt-menu__link-bullet.kt-menu__link-bullet--dot>span {
	background-color: #607d8b;
}

.kt-aside-dark .kt-aside-menu .kt-menu__nav>.kt-menu__item .kt-menu__submenu .kt-menu__item.kt-menu__item--active>.kt-menu__heading .kt-menu__link-bullet.kt-menu__link-bullet--dot>span,
.kt-aside-dark .kt-aside-menu .kt-menu__nav>.kt-menu__item .kt-menu__submenu .kt-menu__item.kt-menu__item--active>.kt-menu__link .kt-menu__link-bullet.kt-menu__link-bullet--dot>span {
	background-color: #607d8b;
}

.btn-primary {
	background-color: #147dbf !important;
	border-color: #147dbf !important;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active {
	background-color: #36a5eb !important;
	border-color: #36a5eb !important;
	color: #fff !important;
}

.btn-primary:disabled {
	background-color: #67666e !important;
	border-color: #67666e !important;
}

.MuiInput-underline:after {
	border-bottom: 2px solid #67666e !important;
}

.MuiFormLabel-root.Mui-focused {
	color: #67666e !important;
}

.MuiStepLabel-label.MuiStepLabel-active {
	color: rgba(0, 0, 0, 0.54) !important;
	font-weight: 400 !important;
}

.margin-icon {
	margin-right: 8px;
}

.btn-table-action>i {
	width: 40px !important;
	font-size: 13px !important;
	padding: 0 !important;
}

.btn-table-action {
	margin: 0px 3px 0px 3px;
	height: 40px;
	min-width: 40px;
	width: 40px;
	padding-left: 0 !important;
	padding-right: 0 !important;
}

.form-group .btn-table-action {
	height: 51.25px;
	width: 51.25px;
}

.btn-table-action-small>i {
	width: 35px !important;
	font-size: 12px !important;
}

.btn-table-action-small {
	height: 30px;
	width: 30px;
	padding: 0 !important;
}

.p-datatable-emptymessage {
	height: 100px !important;
	text-align: center !important;
	font-size: 21px !important;
	font-weight: 600 !important;
}

.p-paginator-bottom {
	padding: 10px !important;
	background: #fff !important;
	border: 1px solid #f4f4f4 !important;
	border-bottom-right-radius: 8px !important;
	border-bottom-left-radius: 8px !important;
	text-align: right !important;
}

.p-paginator-current {
	color: #434349 !important;
}

.p-paginator-page.p-highlight {
	background-color: #147dbf !important;
}

.p-paginator-element.p-link {
	margin: 0px 2px 0px 2px !important;
	border-radius: 5px !important;
}

.p-button {
	margin: 0;
	background-color: #147dbf !important;
	border-radius: 0.25rem !important;
	border: none !important;
}

.p-datatable .p-datatable-thead>tr>th {
	text-align: left !important;
	background-color: #fff;
}

.p-datatable .p-datatable-thead>tr {
	height: 50px !important;
}

.p-datatable .p-datatable-tbody>tr>td {
	border: 1px solid #f4f4f4 !important;
}

.p-datatable .p-datatable-thead>tr>th {
	border: 1px solid #f4f4f4 !important;
}

.p-datatable-header {
	height: min-content;
	background-color: #fff !important;
	border: none !important;
	padding-right: 0 !important;
	padding-left: 0 !important;
}

.p-datatable-loading-overlay.p-component-overlay {
	background-color: #fff !important;
}

.p-datatable-loading-content {
	top: 15% !important;
}

.p-inputtext {
	border: 1px solid #e2e1e1 !important;
}

label.p-dropdown-label.p-inputtext {
	border: none !important;
}

.MuiFormLabel-root.Mui-focused {
	color: #67666e !important;
}

.MuiInput-underline:focus:not(.Mui-focused):before,
.MuiInput-underline:active:not(.Mui-focused):before,
.MuiInput-underline:hover:not(.Mui-disabled):before {
	border-bottom: 1px solid #878691 !important;
}

.MuiInput-underline:after {
	border-bottom: 2px solid #878691 !important;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
	border-color: #67666e !important;
}

.btn>i.fa-spin {
	padding-right: 0px !important;
	margin-right: 16px !important;
}

.kt-portlet__head-title {
	padding-top: 15px !important;
	padding-bottom: 15px !important;
}

.anexoBtnRemove {
	width: 100%;
	border-radius: 0px 0px 10px 10px;
}

.anexoBackground {
	height: 250px;
	width: 100%;
	border-radius: 10px 10px 0px 0px;
	background-size: cover !important;
}

.anexoContainer {
	margin-top: 15px;
	margin-bottom: 15px;
}

.kt-notification__item-icon>i {
	color: #147dbf !important;
}

body a:hover {
	color: #147dbf !important;
}

.kt-login__title {
	color: #424242 !important;
	font-weight: 600 !important;
}

.kt-login__subtitle {
	font-size: 1.3rem !important;
	color: #616161 !important;
}

.kt-login__copyright {
	color: #424242 !important;
}

.kt-login__menu>a {
	text-decoration: none !important;
	color: #424242 !important;
	text-shadow: 1px 1px 5px #fff !important;
}

.kt-login__menu>a:hover {
	text-decoration: none !important;
	color: #212121 !important;
}

.kt-scrolltop {
	background: #147dbf !important;
}

.btn-app-color {
	border-color: #147dbf !important;
	background-color: white !important;
	color: #147dbf !important;
}

.btn-app-color:hover {
	border-color: #147dbf !important;
	background-color: #147dbf !important;
	color: white !important;
}

.MuiInputBase-input.Mui-disabled {
	color: rgba(0, 0, 0, 0.65) !important;
}

.atalho-dashboard {
	background-color: #147dbf !important;
	color: white !important;
	height: 200px !important;
	font-weight: 600 !important;
	display: flex !important;
	align-items: center !important;
	justify-content: center !important;
}

.atalho-dashboard>i {
	font-size: 4.5rem !important;
	margin: 0px 0px 25px 0px !important;
}

.atalho-dashboard>h3 {
	font-size: 1.8rem !important;
	font-weight: 600 !important;
}

.no-content-dashboard {
	margin: 50px 60px 50px 60px;
	display: flex;
	flex: 1;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
}

.no-content-dashboard>i {
	font-size: 5rem !important;
	margin: 0px 0px 40px 0px !important;
}

.no-content-dashboard>h3 {
	font-size: 2.3rem !important;
	font-weight: 600 !important;
}

.no-content-dashboard>p {
	margin-top: 10px !important;
	font-size: 1.2rem !important;
	font-weight: 300 !important;
}

.item-atendimentos-hoje:not(:last-child) {
	margin-bottom: 2.3rem !important;
}

.item-atendimentos-hoje>.kt-timeline-v2__item-text {
	font-size: 1.2rem !important;
	padding: 0.2rem 0 0 5rem !important;
}

.nav-item.nav-link {
	padding: 15px 10px 15px 10px !important;
	color: #444 !important;
	border-radius: 0 !important;
}

.nav-item.nav-link:hover:not(.active) {
	color: #999 !important;
	border: 1px solid transparent !important;
}

.nav-item.nav-link.active {
	border-radius: 0px !important;
	border-top: 3px solid #147dbf !important;
	font-weight: 500 !important;
}

.nav-item.nav-link:first-child {
	border-left: 1px solid transparent !important;
}

.nav-tabs {
	border-bottom: 1px solid #ddd !important;
}

.tab-pane {
	padding: 0px 25px 25px 25px !important;
}

a.btn:hover {
	color: white !important;
}

.MuiSwitch-colorPrimary.Mui-checked {
	color: #147dbf !important;
}

.MuiSwitch-colorPrimary.Mui-checked+.MuiSwitch-track {
	background-color: rgba(20, 125, 191, 0.5) !important;
}

.MuiSwitch-thumb {
	box-shadow: none !important;
}

.col-btn-with-input {
	display: flex;
	align-items: flex-end;
	padding-bottom: 8px;
}

.btn-with-input {
	height: 52px;
}

.label-with-texfield {
	margin-bottom: 0px !important;
}

.tableHeaderItem {
	margin-top: 10px !important;
	margin-bottom: 10px !important;
}

.form-control:focus {
	border-color: #607d8b;
}

.btn.btn-label-brand {
	background-color: white !important;
	color: #147dbf !important;
}

.btn.btn-label-brand:hover,
.btn.btn-label-brand:focus {
	background-color: #147dbf !important;
	color: white !important;
}

.empty-icon {
	margin-top: 50px;
	font-size: 4.2rem;
}

.empty-text {
	margin: 50px 25px 50px 25px;
	font-size: 1.5rem;
}

.scroll::-webkit-scrollbar {
	width: 6px;
	height: 10px;
}

.scroll::-webkit-scrollbar-button {
	width: 0px;
	height: 0px;
}

.scroll::-webkit-scrollbar-thumb {
	background: #808080;
	border: 0px none #ffffff;
}

.scroll::-webkit-scrollbar-thumb:hover {
	background: #808080;
}

.scroll::-webkit-scrollbar-thumb:active {
	background: #808080;
}

.scroll::-webkit-scrollbar-track {
	background: #ccc;
	border: 0px none #ffffff;
}

.scroll::-webkit-scrollbar-track:hover {
	background: #c0c0c0;
}

.scroll::-webkit-scrollbar-track:active {
	background: #c0c0c0;
}

.scroll::-webkit-scrollbar-corner {
	background: transparent;
}

.swal2-popup {
	border-radius: 15px !important;
}

.forgot-password,
.forgot-password:hover {
	color: rgba(0, 0, 0, 0.54);
}

.group-label {
	padding: 10px 15px;
	font-size: 11px;
	text-transform: uppercase;
}

// Start Custom CSS

.white-text {
	color: white !important;
}

.passo-cinco-previa-verde {
	background-color: #0BA55B;
}

.passo-cinco-previa-vermelho {
	background-color: #fd1361;
}

.passo-cinco-previa-white>.kt-portlet {
	border-bottom: 3px solid white !important;
}

.blue-row {
	background-color: #c8daef !important;
}

.first-three-rows {
	background-color: #DCDCDC !important;
}

.last-three-rows {
	background-color: #C8DAEF !important;
}

.card-info-previa {
	box-shadow: 0px 4px 4px 0px #00000040;
}

.passo-um-previa>.kt-portlet {
	border-bottom: 3px solid #0BA55B !important;
}

.passo-dois-previa-verde>.kt-portlet {
	border-bottom: 3px solid #0BA55B !important;

}

.passo-dois-previa-azul>.kt-portlet {
	border-bottom: 3px solid #64A6F3 !important;

}

.passo-dois-previa-amarelo>.kt-portlet {
	border-bottom: 3px solid #FFF496 !important;
}

.card-previa {
	padding: 25px !important;
}

.stepHeader {
	height: 72px;
	background-color: #F2F3F7;
}

.stepHeaderDot {
	height: 32px;
	width: 32px;
	background: #FFFFFF;
	border-radius: 16px;
	margin: 20px 24px;
	text-align: center;
	display: inline-block;

	font-family: 'Inter';
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 30px;
}

.stepHeaderText {
	font-family: 'Inter';
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	color: #33353A;
}

.stepPaginatorText {
	font-family: 'Inter';
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	color: #747882;
	float: right;
	margin-top: 24px;
	margin-bottom: 24px;
	margin-right: 32px;
}

.espacamento-vertical>div {
	margin-bottom: 32px;
}

body .p-datatable .p-datatable-tfoot>tr>td {
	text-align: left !important;
}

.custom-datatable-header {
	float: left;
	margin-bottom: 16px;
}

.imagemUploaderBotaoRemover {
	width: 100%;
	border-radius: 0px 0px 10px 10px;
}

.imagemUploaderBackground {
	height: 250px;
	width: 100%;
	border-radius: 10px 10px 0px 0px;
	background-size: cover !important;

	&.logoUploaderCustomBackground {
		height: 256px;
	}
}

.imagemUploaderMargem {
	margin-top: 15px;
	margin-bottom: 15px;
}

.fileUploaderBackground {
	width: 100%;
	border-radius: 10px 10px 0px 0px;
	background-color: #cdcdcd;
	height: 200px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.btn-filtros {
	margin-right: 5px;
}

body .fc th {
	padding: 0;
}

.eventWithPlan {
	background-color: #0abb87 !important;
	border-color: #0abb87 !important;
}

.eventWithPlan:hover {
	background-color: #058f66 !important;
	border-color: #058f66 !important;
}

.eventClientInadimplente {
	background-color: #5E4DB2 !important;
	border-color: #5E4DB2 !important;
}

.eventClientInadimplente:hover {
	background-color: #4b3aa3 !important;
	border-color: #4b3aa3 !important;
}

.eventFinished {
	background-color: #a9a9a9 !important;
	border-color: #a9a9a9 !important;
}

.eventFinished:hover {
	background-color: #727171 !important;
	border-color: #727171 !important;
}

.eventWithPlan>.fc-daygrid-event-dot {
	background-color: #0abb87 !important;
	border-color: #0abb87 !important;
}

.alert-danger-div {
	margin: 20px;
}

.card-info {
	// border: 1px solid rgba(0, 0, 0, 0.35);
	border-radius: 4px;
	margin-bottom: 16px;
	padding: 20px;
	background-color: #e8e8e8;
}

.text-info {
	color: rgba(0, 0, 0, 0.7) !important;
	margin-bottom: 0;
	font-size: 14px;
	font-weight: 500;
	text-align: center;
}

.fc-event {
	border: 1px solid #36a5eb !important;
}

.fc-day-number {
	color: #424242 !important;
}

.fc-toolbar>*> :not(:first-child) {
	margin-left: 0.4em !important;
}

.fc .fc-daygrid-day-frame {
	min-height: 124px !important;
}

.fc .fc-col-header-cell-cushion {
	color: rgb(31, 29, 29) !important;
}

.fc .fc-daygrid-day-number {
	color: rgb(31, 29, 29) !important;
}

.fc th .fc-scrollgrid-sync-inner {
	background-color: lightgrey !important;
}

.fc-button-primary {
	color: #36a5eb !important;
	background-color: white !important;
	border-color: #36a5eb !important;
	border-radius: 5px !important;
	height: 40px !important;
	padding-left: 15px !important;
	padding-right: 15px !important;
	margin: 5px !important;
}

.fc-button-primary:hover {
	color: white !important;
	background-color: #36a5eb !important;
	border-color: #36a5eb !important;
}

.fc-button-primary:not(:disabled):active,
.fc-button-primary:not(:disabled).fc-button-active {
	color: #fff !important;
	background-color: #36a5eb !important;
	border-color: #36a5eb !important;
}

.fc-button-primary:disabled {
	color: #fff !important;
	background-color: #116fbf !important;
	border-color: #116fbf !important;
}

.fc-center>h2 {
	color: #36a5eb !important;
}

.fc .fc-row {
	border-right: none !important;
}

.fc th {
	background-color: #fafafa !important;
	border: 1px solid #ddd !important;
	color: inherit !important;
}

a.fc-event:hover {
	color: white !important;
}

body .fc .fc-daygrid-event-harness .eventWithNoPlan {
	background-color: #36a5eb !important;
}

body .fc .fc-daygrid-event-harness .eventWithNoPlan:hover {
	background-color: #116fbf !important;
}

.input-comanda-total>div>fieldset {
	border-width: 2px;
	border-color: rgba(0, 0, 0, 0.65) !important;
}

.input-comanda-total>div>input {
	font-weight: 800;
}

.input-comanda-total>label {
	color: rgba(0, 0, 0, 0.65) !important;
	font-weight: 800;
}

.status_cliente_label {
	margin: 0;
	font-size: 1.2rem;
	font-weight: 500;
	color: #48465b;
	padding-top: 15px !important;
	padding-bottom: 15px !important;
}

.dropdown-menu {
	padding: 0.5rem;
}

.dialogListRow {
	margin: 32px;
	min-width: 400px;
}

.blur {
	filter: blur(1.5rem);
}

.modal-sm>.modal-content {
	width: 500px;
	margin: auto
}

.container-historico-caixas {
	max-width: 1600px;
}

.historico-caixas-list-header {
	background-color: white;
	padding: 0.75rem 1.25rem;
}

.card-historico-caixas-list {
	min-height: 5rem;
	padding-top: 1rem;
}

.list-group {
	max-height: 1140px;
	margin-bottom: 10px;
	overflow: scroll;
	-webkit-overflow-scrolling: touch;
}

.flex-col-reverse {
	flex-direction: column-reverse;
}

.contained-button-primary {
	background: #0abb87 !important;

}

.no-click {
	cursor: default !important;
}

.legend {
	padding-left: 0;
	list-style: none;
}

.legend li {
	margin-right: 10px;
}

.legend span {
	border: 1px solid #ccc;
	float: left;
	width: 12px;
	height: 12px;
	margin: 2px;
}

/* your colors */
.legend .complete {
	background-color: #00BB88;
}

.legend .ocioso {
	background-color: #e0e0de;
}

.legend .ocioso.previa-passo-um {
	background-color: #EDA49F;
}

.legend .ocioso.previa-passo-seis {
	background-color: #FFF496;
}

.accordion>.btn-link:hover {
	background-color: #64b3e5;
}

.accordion>.btn-link:active {
	background-color: #64b3e5 !important;
}

.accordion>.btn-link:focus {
	background-color: #64b3e5 !important;
}

.accordion>.btn-link:active>p {
	color: white;
}

.accordion>.btn-link:focus>p {
	color: white;
}

.accordion>.btn-link:hover>p {
	color: white;
}

@media screen and (min-width: 768px) {
	.modal-dialog {
		max-width: 700px;
		margin: 1.75rem auto;
	}
}

@media screen and (max-width: 768px) {
	.dialogListRow {
		min-width: 0px !important;
	}
}

.infoTermoDeUso {
	color: #5a5f63;
	text-decoration-line: underline !important;

	.comanda-info-text {
		color: black;
	}

	// End Custom CSS

	@media screen and (max-width: 996px) {
		.kt-portlet {
			margin: 5px;
		}

		.alert-danger-div {
			margin: 5px !important;
		}

		.kt-portlet__body .btn {
			margin-bottom: 0px !important;
		}

		.div-buttons {
			display: flex;
			flex-direction: row-reverse;
			flex-wrap: wrap;
			width: 60%;
			gap: 8px;
			justify-content: space-between;
		}

		.div-buttons>button {
			width: 117px;
			margin-right: 0px !important;
		}
	}
}

.card-comandas-list {
	min-height: 2rem;
	padding-top: 0.25rem;
	display: grid;
}

.calendar-aside {
	background-color: white;
	padding: 16px;
	display: inline-block;
	margin-top: 5px;
	width: 100%;
	min-height: calc(100vh - 75px);

	.toggle-arrow {
		i {
			transition: transform 0.3s;
			transform: rotate(0, 0);
		}

		&.arrow-toggled {
			i {
				transform: scale(-1, -1);
			}
		}
	}
}

.toggle-arrow {
	text-align: center;
	margin-bottom: 32px;
	background-color: #1e1e2d
}

.toggle-arrow:hover {
	cursor: pointer;
	background-color: white
}

.toggle-arrow i {
	padding-right: 0px !important;
	color: white;
}

.toggle-arrow:hover i {
	color: #1e1e2d;
}

.beauty-title {
	color: #646c9a;
	font-family: 'Poppins';
}

@media screen and (max-width: 1022px) {
	.calendar-aside {
		background-color: white;
		padding: 16px;
		display: inline-block;
		margin: 10px 0px;
		width: 100%;
		min-height: 0px !important;
	}
}

.pe-none {
	cursor: default;
}

.whatsapp-float {
	position: fixed;
	width: 60px;
	height: 60px;
	bottom: 40px;
	right: 40px;
	background-color: #25d366;
	color: #FFF;
	border-radius: 50px;
	text-align: center;
	font-size: 30px;
	box-shadow: 2px 2px 3px #999;
	z-index: 100;
}

.whatsapp-float-icon {
	margin-top: 16px;
	color: white;
}

.whatsapp-float:hover {
	text-decoration: none !important;
}

// AI CSS

.kt-header {
	border-bottom: 1px solid #CCCCCC !important;
}

.kt-header__topbar-welcome {
	color: #25262D !important;
	font-size: 14px !important;
	font-weight: 400 !important;
}

.kt-header__topbar-username {
	color: #25262D !important;
	font-size: 14px !important;
}

.btn-ai-header {
	width: 36px;
	height: 36px;
	border-radius: 6px;
	border: 1px solid #CCCCCC;
	background-color: #FFF;
	color: #222222;
	margin-top: 14px;
	margin-right: 16px;

	&.active {
		background-color: #E4E4E4;
		border: 1px solid #E4E4E4;
	}

	&.mobile {
		margin: 0 0 0 1.5rem;
	}
}

.btn-ai-header:hover {
	background-color: #E4E4E4;
	border: 1px solid #E4E4E4;
}

.ai-chat-container {
	background-color: rgba(0, 0, 0, 0.5);
	width: 100%;
	height: 100%;
	z-index: 101;
	position: fixed;
	display: flex;
	flex-direction: row-reverse;
	left: 0;
}

.ai-chat-container-content {
	@keyframes slideTopToBottom {
		from {
			opacity: 0;
			transform: translateY(-100%);
		}

		to {
			opacity: 1;
			transform: translateY(0%);
		}
	}

	animation: slideTopToBottom 0.5s ease-out;
	background-color: white;
	width: 680px;
	z-index: 110;
	position: relative;

	&.close {
		@keyframes slideBottomToTop {
			from {
				opacity: 1;
				transform: translateY(0%);
			}

			to {
				opacity: 0;
				transform: translateY(-100%);
			}
		}

		animation: slideBottomToTop 0.5s ease-in-out;
	}

	display: flex;
	flex-direction: column;
}

.ai-chat-body {
	display: flex;
	flex-direction: column;

	&.expanded {
		flex-direction: row-reverse;
	}
}

.ai-chat {
	position: absolute;
	width: 100%;
	height: calc(100vh - 272px);
	opacity: 0;
	transform: translateY(100%);
	transition: opacity 0.5s ease, transform 0.5s ease;

	&.chatList {
		height: calc(100vh - 86px);

		&.expanded {
			width: auto;

			.ai-chat-list-container .ai-chat-list-item {
				padding: 24px;
				max-width: 300px;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}
	}

	&.active {
		z-index: 102;
		opacity: 1;
		transform: translateY(0%);
	}

	&.newChat {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	&.expanded {
		transform: translateY(0%);
		opacity: 1;
		position: unset;
	}

}

.ai-chat-container-content-expanded {
	width: 100% !important;

	.ai-chat-welcome-text {
		font-family: 'Kumbh Sans';
		font-size: 32px;
		font-weight: 600;
		line-height: 40px;
		color: #666666;
	}
}

.ai-chat-welcome-text {
	font-family: 'Kumbh Sans';
	font-size: 24px;
	font-weight: 600;
	color: #666666;
}

.ai-chat-container .kt-container {
	padding: 0 !important;
}

.ai-chat-name {
	font-family: 'Kumbh Sans';
	font-size: 16px;
	font-weight: 600;
	color: #000;
	max-width: 50%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.ai-chat-options-container {
	width: 100%;
	height: 72px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 24px;
	border-bottom: 1px solid #CCCCCC !important;
	z-index: inherit;
	background-color: #fff;
}

.ai-chat-options-btns-left {
	display: flex;
	gap: 16px;
	transition: transform 0.5s ease-in-out;

	@keyframes opacityAnimationIn {
		from {
			opacity: 0;
		}

		to {
			opacity: 1;
		}
	}

	@keyframes opacityAnimationOut {
		from {
			opacity: 1;
		}

		to {
			opacity: 0;
		}
	}

	@keyframes slideLeftToRightT {
		from {
			transform: translateX(-40px);
		}

		to {
			transform: translateX(0px);
		}
	}

	@keyframes slideRightToLeftT {
		from {
			transform: translateX(0px);
		}

		to {
			transform: translateX(-40px);
		}
	}

	&.showToggleChatList {
		animation: slideLeftToRightT 0.5s ease-in-out;

		.toggleChatList {
			animation: opacityAnimationIn 0.5s ease-in-out;
		}
	}

	// &.hideToggleChatList {
	// 	animation: slideRightToLeftT 0.5s ease-in-out;
	// 	.toggleChatList {
	// 		animation: opacityAnimationOut 0.5s ease-in-out;
	// 	}
	// }

	.toggleChatList {
		&.onShow {
			margin-left: 0px;
		}

		&.hide:not(&.onShow) {
			opacity: 0;
			margin-left: -40px;
		}
	}
}

.btn-chat-options {
	width: 24px;
	height: 24px;
	padding: 5px;
	color: #222222;
	border: none;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: transparent;
}

.btn-chat-options:has(:hover, :active, :focus) {
	background-color: rgba(0, 0, 0, 0.02);
}

.ai-chat-messages-container {
	height: inherit;
	padding: 16px 24px;
	display: flex;
	flex-direction: column;
	gap: 32px;
	overflow: auto;

	&.newChat {
		height: auto;
	}
}

.ai-chat-message-from-ai {
	display: flex;
	flex-direction: row;
	gap: 24px;
	align-self: flex-start;

	.ai-chat-message-from-ai-avatar {
		width: 40px;
		min-width: 40px;
		height: 40px;
	}

	.ai-chat-message-from-ai-message-container {
		display: flex;
		flex-direction: column;
		gap: 12px;

		.message {
			font-size: 16px;
			font-weight: 400;
			font-family: "Kumbh Sans";
			color: #000;
			display: flex;
			flex-direction: column;
			gap: 8px;

			p,
			h1,
			h2,
			h3,
			ol,
			ul {
				margin: 0px !important;
			}
		}

		.feedback {
			width: 100%;
			display: flex;
			flex-direction: row;
			gap: 12px;

			button {
				width: 19px;
				height: 19px;
				padding: 0px;
				color: #999999;
				border: none;
				display: flex;
				justify-content: center;
				align-items: center;
				background-color: transparent;
			}
		}
	}
}

.ai-chat-message-from-user {
	max-width: 568px;
	max-width: calc(100% - 64px);
	background-color: #E4E4E4;
	border-radius: 8px;
	font-family: "Kumbh Sans";
	font-size: 16px;
	font-weight: 400;
	color: #000;
	padding: 24px;
	align-self: flex-end;
}

.ai-chat-input-container {
	height: fit-content;
	padding: 24px;

	&.newChat {
		width: 100%;
		max-width: 840px;
	}
}

.ai-chat-input-text {
	height: fit-content;
	padding: 16px;
	background-color: #E4E4E4;
	border-radius: 8px;
	display: flex;
	overflow: hidden;
	text-align: center;
}

.submit-button {
	width: 56px;
	height: 56px;
	border-radius: 8px;
	padding: 16px;
	background-color: #284EEA;
	border: none;
	margin-left: 16px;
	align-self: center;
}

.ai-chat-list-container {
	overflow-x: auto;
	height: calc(100vh - 137px);
	min-width: 300px;
}

.ai-chat-list-item {
	padding: 16px 16px;
	font-size: 16px;
	font-weight: 500;
	color: #777777;
	font-family: 'Kumbh Sans';
	cursor: pointer;

	&.selected {
		background-color: rgba(0, 0, 0, 0.1);
		font-weight: 700;
	}
}

.ai-chat-list-item:is(:hover, :active, :focus) {
	background-color: rgba(0, 0, 0, 0.1);
}

@media screen and (max-width: 1024px) {
	.ai-chat {
		height: calc(100vh - 225px);
	}

	.ai-chat-container-content {
		width: 100% !important;
	}

	.ai-chat-input-container {
		height: 102px !important;
		padding: 16px !important;
	}

	.ai-chat-list-item {
		font-size: 14px !important;
	}

	.ai-chat-messages-container {
		padding: 16px !important;
	}

	.ai-chat-message-from-user {
		font-size: 14px !important;
		padding: 16px !important;
		max-width: calc(100% - 46px);
	}

	.ai-chat-message-from-ai {
		gap: 16px !important;
	}

	.ai-chat-message-from-ai-avatar {
		width: 30px !important;
		min-width: 30px !important;
		height: 30px !important;
	}

	.ai-chat-name {
		font-size: 14px !important;
		font-weight: 500 !important;
	}

	.message {
		font-size: 14px !important;

		ol {
			padding-inline-start: 20px !important;

			li ul {
				padding-inline-start: 20px !important;
			}
		}

	}

	.ai-chat-welcome-text {
		font-size: 20px !important;
	}

	.ai-chat-input-text {
		height: 70px !important;
	}

	.submit-button {
		height: 38px !important;
		width: 38px !important;
		padding: 8px !important;

		img {
			width: 16px !important;
			height: 16px !important;
		}
	}
}

/* Loading dots */
.dot-container {
	gap: 4px;
	min-width: 40px;
	min-height: 20px;
	height: 100%;
	align-items: center;
	justify-content: center;
}

.dot {
	width: 8px;
	height: 8px;
	background-color: #999999;
	border-radius: 50%;
	display: inline-block;
}

@keyframes bounce {

	0%,
	50%,
	100% {
		transform: translateY(0);
	}

	75% {
		transform: translateY(-5px);
	}

}

.animate-bounce {
	animation: bounce 0.9s infinite ease-in-out;
}

.delay-0 {
	animation-delay: 0s;
}

.delay-200 {
	animation-delay: 0.2s;
}

.delay-400 {
	animation-delay: 0.4s;
}

/* Loading dots */


/* Width */
::-webkit-scrollbar {
	width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
	background: #f1f1f1;
	border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: rgba(0, 0, 0, 0.3);
	border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: rgba(0, 0, 0, 0.4);
}
